import * as React from "react";
import {Carousel, Modal} from "antd";
import {useEffect, useState} from "react";

const SimulationsScreen: React.FC = () => {
    const [tutorial, setTutorial] = useState((localStorage.getItem("tutorial") ?? 'true') === 'true');
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(tutorial);
    const [income, setIncome] = useState(localStorage.getItem("income") ?? "0");
    const [goal, setGoal] = useState(localStorage.getItem("goal") ?? "0");
    const [inputStep, setInputStep] = useState(parseInt(localStorage.getItem("inputStep") ?? "0"));

    const handleOk = () => {
        setIsHelpModalOpen(false);
    };

    const handleCancel = () => {
        setIsHelpModalOpen(false);
    };

    const handleFinish = () => {
        setIsHelpModalOpen(false);
        setTutorial(false);
    };

    const handleContinue = (value: (value: string) => void) => {
        setInputStep(inputStep + 1);
        value((document.getElementById("income") as HTMLInputElement).value);
    };

    const contentStyle: React.CSSProperties = {
        margin: 10,
        padding: 10
    };

    useEffect(() => {
        localStorage.setItem("income", income);
    }, [income]);

    useEffect(() => {
        localStorage.setItem("goal", goal);
    }, [goal]);

    useEffect(() => {
        localStorage.setItem("tutorial", tutorial.toString());
    }, [tutorial]);

    useEffect(() => {
        localStorage.setItem("inputStep", inputStep.toString());
    }, [inputStep]);

    interface InputProps {
        header: string;
        question: string;
        setValue: (value: string) => void;
    }

    interface InputItemProps {
        step: number;
    }

    const RenderInputSection: React.FC<InputProps> = ({header, question, setValue}) => {
        return (
            <div>
                <section
                    className="justify-center items-center self-stretch px-16 py-24 text-8xl text-white bg-indigo-700 rounded-[147px] max-md:px-5 max-md:max-w-full max-md:text-4xl">
                    {header}
                </section>
                <h1 className="mt-11 text-8xl text-indigo-700 max-md:mt-10 max-md:max-w-full max-md:text-4xl">{question}</h1>
                <fieldset
                    className="flex justify-center items-center px-16 py-20 mt-7 w-full whitespace-nowrap bg-zinc-300 max-w-[1141px] rounded-[149px] max-md:px-5 max-md:max-w-full">
                    <legend className="sr-only">Input</legend>
                    <div className="flex flex-col max-w-full w-[939px]">
                        <div className="flex gap-5 text-6xl max-md:flex-wrap max-md:max-w-full max-md:text-4xl">
                            <input
                                className="grow justify-center items-start px-14 py-2.5 bg-indigo-700 rounded-lg shadow-sm text-white text-opacity-40 w-fit max-md:px-5 max-md:max-w-full max-md:text-4xl"
                                type="text"
                                placeholder="1234567..."
                                aria-label="Income Input"
                                id="income"
                            />
                            <span
                                className="justify-center px-6 py-2.5 text-white bg-indigo-700 rounded-lg shadow-sm max-md:px-5 max-md:text-4xl">€</span>
                        </div>
                        <button onClick={() => handleContinue(setValue)}
                            className="items-center self-center px-16 pt-5 pb-3 mt-28 max-w-full text-5xl text-white bg-indigo-700 rounded-lg shadow-sm w-[412px] max-md:px-5 max-md:mt-10 max-md:text-4xl"
                        >
                            CONTINUE
                        </button>
                    </div>
                </fieldset>
            </div>
        );
    }

    const InputItem: React.FC<InputItemProps> = ({step}) => {
        if (step === 0) {
            return <RenderInputSection header="FIRST THINGS FIRST" question="WHAT IS YOUR INCOME?"
                                       setValue={setIncome}/>;
        } else if (step === 1) {
            return <RenderInputSection header="SECOND THINGS SECOND" question="WHAT IS YOUR SAVING GOAL?"
                                       setValue={setGoal}/>;
        } else if (step === 2) {
            window.location.href = "/dashboard";
        }
        return null;
    }

    return (
        <div
            className="flex flex-col items-center px-4 pt-4 pb-9 w-full font-bold text-center bg-zinc-100 max-md:max-w-full">
            <InputItem step={inputStep}/>
            <Modal width="70%" open={isHelpModalOpen} onOk={handleOk} onCancel={handleCancel} closable={false}
                   footer={null}>
                <Carousel arrows infinite={false}>
                    <div style={contentStyle}>
                        <div
                            className="my-20 px-20 text-4xl font-bold text-center text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                            <span className="text-indigo-700"> Hi!</span>
                            Welcome to Financial Simulations
                            <span className="text-indigo-700">.</span>
                            Let’s go over the
                            <span className="text-indigo-700"> basics.</span>
                        </div>
                    </div>
                    <div style={contentStyle}>
                        <div
                            className="my-20 px-20 text-4xl font-bold text-center text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                            Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Donec qu
                        </div>
                    </div>
                    <div style={contentStyle}>
                        <div
                            className="my-20 px-20 text-4xl font-bold text-center text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                            Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Donec qu
                        </div>
                    </div>
                    <div style={contentStyle}>
                        <div
                            className="my-20 px-20 text-4xl font-bold text-center text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                            Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Donec qu
                        </div>
                    </div>
                    <div style={contentStyle}>
                        <div
                            className="my-20 px-20 text-4xl font-bold text-center text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                            Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Donec qu
                        </div>
                    </div>
                    <div style={contentStyle}>
                        <div
                            className="my-20 px-20 text-4xl font-bold text-center text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.
                            Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Donec qu
                        </div>
                        <button onClick={handleFinish}>Finish</button>
                    </div>
                </Carousel>
            </Modal>
        </div>
    );
};

export default SimulationsScreen;