import * as React from "react";
import { useState } from "react";

import image1 from "../public/image 6.png";
import image2 from "../public/image 7.png";
import image3 from "../public/image 8.png";
import image4 from "../public/image 9.png";
import image5 from "../public/image 10.png";

interface Category {
    name: string;
    value: number;
    color: string;
    imageUrl: string;
}

const categories: Category[] = [
    { name: "Food", value: 250, color: "bg-yellow-500", imageUrl: image1 },
    { name: "Gas", value: 210, color: "bg-yellow-900", imageUrl: image2 },
    { name: "Utilities", value: 360, color: "bg-red-600", imageUrl: image3 },
    { name: "Leisure", value: 130, color: "bg-cyan-400", imageUrl: image4 },
    { name: "Sample", value: 70, color: "bg-purple-600", imageUrl: image5 },
];

interface BudgetingScreenProps {}

const BudgetingScreen: React.FC<BudgetingScreenProps> = () => {
    const [income] = useState(localStorage.getItem("income") ?? "0");
    const [goal] = useState(localStorage.getItem("goal") ?? "0");

    return (
        <div className="flex flex-col">
            <div className="flex flex-col px-14 pt-3.5 pb-8 w-full max-md:px-5 max-md:max-w-full">
                <div className="px-0.5 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                            <div
                                className="flex flex-col grow px-10 pt-6 w-full text-5xl font-bold justify-center items-center text-white bg-indigo-700 rounded-[38px] max-md:px-5 max-md:mt-10 max-md:text-4xl"
                            >
                                <div className="flex justify-center max-md:text-4xl">My income:</div>
                                <div className="z-10 flex px-16 py-7 mt-7 whitespace-nowrap bg-indigo-800 rounded-[147px] max-md:px-5 max-md:text-4xl">
                                    {income}€
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                            <div
                                className="flex flex-col grow px-9 pt-6 w-full text-5xl font-bold justify-center items-center text-white bg-emerald-400 rounded-[38px] max-md:px-5 max-md:mt-10 max-md:text-4xl"
                            >
                                <div className="flex justify-center max-md:text-4xl">My savings:</div>
                                <div className="z-10 flex px-16 py-7 mt-7 whitespace-nowrap bg-emerald-500 rounded-[147px] max-md:px-5 max-md:text-4xl">
                                    123€
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                            <div
                                className="flex flex-col grow px-16 pt-6 w-full text-5xl font-bold justify-center items-center text-white bg-emerald-600 rounded-[38px] max-md:px-5 max-md:mt-10 max-md:text-4xl"
                            >
                                <div className="flex justify-center max-md:text-4xl">My goal:</div>
                                <div className="z-10 flex px-16 py-7 mt-7 whitespace-nowrap bg-green-900 rounded-[147px] max-md:px-5 max-md:text-4xl">
                                    {goal}€
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap gap-4 self-center mt-5 ml-3 text-5xl font-bold text-black whitespace-nowrap max-md:flex-wrap max-md:text-4xl">
                    {categories.map((category) => (
                        <div key={category.name} className="flex flex-col items-center">
                            <div
                                className={`shrink-0 self-start rounded-full flex items-center justify-center ${category.color}`}
                                style={{ width: `${category.value}px`, height: `${category.value}px` }}
                            >
                                <img
                                    src={category.imageUrl}
                                    className={`w-8/12 h-8/12`}
                                    alt={`${category.name} icon`}
                                />
                            </div>
                            <div className="flex-auto max-md:text-4xl">{category.name}</div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-wrap gap-4 mt-12 ml-3 justify-center text-3xl text-black">
                    {categories.map((category) => (
                        <div key={`${category.name}_legend`} className="flex items">
                            <div className={`h-4 w-4 rounded-full ${category.color} mr-2`}></div>
                            <div>{category.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BudgetingScreen;