import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import React, {useState} from "react";

type Props = {};

const DashboardScreen: React.FC<Props> = () => {
    const [values, setValues] = useState({
        Rent: 20,
        Utilities: 15,
        Groceries: 25,
        Leisure: 10,
        Savings: 30,
    });

    const handleChange = (category: string, value: number) => {
        setValues((prevValues) => ({
            ...prevValues,
            [category]: value,
        }));
    };

    const data = {
        labels: ["Rent", "Utilities", "Groceries", "Leisure", "Savings"],
        datasets: [
            {
                data: Object.values(values),
                backgroundColor: [
                    "#4B5563",
                    "#3B82F6",
                    "#10B981",
                    "#EF4444",
                    "#8B5CF6",
                ],
                hoverBackgroundColor: [
                    "#6B7280",
                    "#60A5FA",
                    "#34D399",
                    "#F87171",
                    "#A78BFA",
                ],
            },
        ],
    };

    return (
            <div className="px-6 pt-5 pb-20 w-full bg-zinc-100 max-md:pl-5 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-[29%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col max-md:mt-5">
                            <button
                                className="text-7xl font-bold text-center text-black max-md:text-4xl"
                                onClick={() => handleChange("Rent", values.Rent)}
                            >
                                Rent
                            </button>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={values.Rent}
                                onChange={(e) =>
                                    handleChange("Rent", parseInt(e.target.value))
                                }
                                className="mt-4"
                            />
                            <button
                                className="mt-9 text-7xl font-bold text-center text-black max-md:text-4xl"
                                onClick={() => handleChange("Utilities", values.Utilities)}
                            >
                                Utilities
                            </button>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={values.Utilities}
                                onChange={(e) =>
                                    handleChange("Utilities", parseInt(e.target.value))
                                }
                                className="mt-2.5"
                            />
                            <button
                                className="mt-9 text-7xl font-bold text-center text-black max-md:text-4xl"
                                onClick={() => handleChange("Groceries", values.Groceries)}
                            >
                                Groceries
                            </button>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={values.Groceries}
                                onChange={(e) =>
                                    handleChange("Groceries", parseInt(e.target.value))
                                }
                                className="mt-2.5"
                            />
                            <button
                                className="mt-9 text-7xl font-bold text-center text-black max-md:text-4xl"
                                onClick={() => handleChange("Leisure", values.Leisure)}
                            >
                                Leisure
                            </button>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={values.Leisure}
                                onChange={(e) =>
                                    handleChange("Leisure", parseInt(e.target.value))
                                }
                                className="mt-2.5"
                            />
                            <button
                                className="mt-8 text-7xl font-bold text-center text-black max-md:text-4xl"
                                onClick={() => handleChange("Savings", values.Savings)}
                            >
                                Savings
                            </button>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={values.Savings}
                                onChange={(e) =>
                                    handleChange("Savings", parseInt(e.target.value))
                                }
                                className="mt-2.5"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col ml-20 w-[40%] max-md:ml-0 max-md:w-full">
                        <Pie data={data} />
                    </div>
                </div>
            </div>
    );
};

export default DashboardScreen;