import * as React from "react";
import Logo from "../public/logo.png";
import {Outlet} from "react-router-dom";
import {useState} from "react";
import {Modal} from "antd";
type HomeScreenProps = {};

const HomeScreen: React.FC<HomeScreenProps> = () => {
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isForgotModalOpen, setIsForgotModalOpen] = useState(false);

    const handleLoginClick = () => {
        setIsLoginModalOpen(true);
    };

    const handleRegisterClick = () => {
        setIsRegisterModalOpen(true);
    };

    const handleForgotClick = () => {
        setIsLoginModalOpen(false);
        setIsForgotModalOpen(true);
    };

    const handleHomeClick = () => {
        window.location.href = "/";
    };

    const handleSimulationsClick = () => {
        window.location.href = "/simulations";
    };

    const handleBudgetingClick = () => {
        window.location.href = "/budgeting";
    };

    const handleAboutusClick = () => {
        window.location.href = "/aboutus";
    };

    const handleContactClick = () => {
        window.location.href = "/contact";
    };

    const handleOk = () => {
        setIsRegisterModalOpen(false);
        setIsLoginModalOpen(false);
        setIsForgotModalOpen(false);
    };

    const handleCancel = () => {
        setIsRegisterModalOpen(false);
        setIsLoginModalOpen(false);
        setIsForgotModalOpen(false);
    };

    type InputFieldProps = {
        id: string;
        label: string;
        type: string;
    };

    const InputField: React.FC<InputFieldProps> = ({ id, label, type }) => (
        <div className="mt-3 w-full max-md:mt-10">
            <label htmlFor={id} className="sr-only">
                {label}
            </label>
            <input
                id={id}
                type={type}
                className="justify-center items-start px-8 py-3.5 rounded-lg bg-zinc-300 max-md:px-5 w-full"
                aria-label={label}
                placeholder={label}
            />
        </div>
    );

    return (
        <div>
            <div className="p-2 w-full bg-zinc-300 max-md:max-w-full">
                <div className="flex gap-5 items-center justify-between max-md:flex-col max-md:gap-0">
                    <div
                        className="flex flex-shrink-0 gap-3.5 text-xl font-bold whitespace-nowrap max-md:mt-5 cursor-pointer"
                        onClick={handleHomeClick}
                    >
                        <div
                            className="flex justify-center items-center text-black rounded-full bg-transparent h-[60px] w-[60px]"
                        >
                            <img src={Logo} alt="Logo" className="h-full w-full object-contain" />
                        </div>
                        <div className="my-auto text-indigo-700"> BUDGET <br /> BUDDIES</div>
                    </div>
                    <div
                        className="flex gap-5 justify-center flex-grow max-md:flex-col max-md:gap-0 text-xl font-bold text-indigo-700">
                        <div onClick={handleSimulationsClick} className="cursor-pointer">SIMULATIONS</div>
                        <div onClick={handleBudgetingClick} className="cursor-pointer">BUDGETING</div>
                        <div onClick={handleAboutusClick} className="cursor-pointer">ABOUT US</div>
                        <div onClick={handleContactClick} className="cursor-pointer">CONTACT</div>
                    </div>
                    <div className="flex gap-3.5 whitespace-nowrap font-bold">
                    <button
                        onClick={handleLoginClick}
                        className="px-11 py-1 text-indigo-700 bg-white rounded-lg max-md:px-4"
                    >
                        LOGIN
                    </button>
                    <button
                        onClick={handleRegisterClick}
                        className="px-8 py-1 text-white bg-indigo-700 rounded-lg max-md:px-3"
                    >
                        REGISTER
                    </button>
                </div>
                </div>
            </div>
            <Outlet />
            <Modal title="Register form" open={isRegisterModalOpen} onOk={handleOk} onCancel={handleCancel} closable={false} footer={null} >
                    <form>
                        <InputField id="name" label="Name" type="text"/>
                        <InputField id="lastname" label="Lastname" type="text"/>
                        <InputField id="dob" label="Date of Birth (choose)" type="date"/>
                        <InputField id="email" label="Email Address" type="email"/>
                        <InputField id="password" label="Password" type="password"/>
                        <InputField id="confirm-password" label="Confirm Password" type="password"/>
                        <div className="text-center">
                            <button type="submit" className=" text-white justify-center items-center px-16 py-2 mt-16 max-w-full text-4xl bg-indigo-700 rounded-lg w-[324px] max-md:px-5 max-md:mt-10">SUBMIT</button>
                        </div>
                    </form>
                    <p className="mt-4 text-l text-end text-indigo-400 max-md:mt-10">Need help?</p>
            </Modal>
            <Modal title="Login form" open={isLoginModalOpen} onOk={handleOk} onCancel={handleCancel} closable={false} footer={null} >
                <form>
                    <InputField id="email" label="Email Address" type="email"/>
                    <InputField id="password" label="Password" type="password"/>
                    <div className="text-center">
                        <button type="submit" className=" text-white justify-center items-center px-16 py-2 mt-16 max-w-full text-4xl bg-indigo-700 rounded-lg w-[324px] max-md:px-5 max-md:mt-10">SUBMIT</button>
                    </div>
                </form>
                <p className="mt-4 text-l text-end text-indigo-400 max-md:mt-10">Need help?</p>
                <p onClick={handleForgotClick} className="cursor-pointer mt-2 text-l text-end text-indigo-400 max-md:mt-10">Forgot password?</p>
            </Modal>
            <Modal title="Reset form" open={isForgotModalOpen} onOk={handleOk} onCancel={handleCancel} closable={false} footer={null} >
                <form>
                    <InputField id="email" label="Email Address" type="email"/>
                    <div className="text-center">
                        <button type="submit" className=" text-white justify-center items-center px-16 py-2 mt-16 max-w-full text-2xl bg-indigo-700 rounded-lg w-[324px] max-md:px-5 max-md:mt-10">RESET PASSWORD</button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default HomeScreen;