import * as React from "react";
import discover from "../public/discover.png";
import learn from "../public/learn.png";
import grow from "../public/grow.png";
import john from "../public/john.jpeg";
import jane from "../public/jane.jpeg";
import janice from "../public/janice.jpeg";
import hero from "../public/hero.png"; 
import simulationsImage from "../public/hero-simulations.png"; 
import budgetingImage from "../public/hero-budgeting.png"; 

type HomeScreenProps = {};

const HomeScreen: React.FC<HomeScreenProps> = () => {
    const handleLearnMoreClick = () => {
        window.location.href = "/contact";
    };

    const handleBudgetingClick = () => {
        window.location.href = "/budgeting";
    };

    const handleSimulationsClick = () => {
        window.location.href = "/simulations";
    };

    type CardProps = {
        imageSrc: string;
        title: string;
        description: string;
        imageAlt: string;
    };

    type TestimonialProps = {
        name: string;
        description: string;
        imgSrc: string;
    };

    const Card: React.FC<CardProps> = ({ imageSrc, title, description, imageAlt }) => (
        <section className="flex flex-col items-center mt-6 max-md:mt-10">
            <img src={imageSrc} alt={imageAlt} className="max-w-full aspect-square w-[115px]" />
            <h2 className="mt-9 text-4xl font-bold text-indigo-700">{title}</h2>
            <p className="self-stretch mt-5 text-xl text-center text-black">
                {description}
            </p>
        </section>
    );

    const Testimonial: React.FC<TestimonialProps> = ({ name, description, imgSrc }) => (
        <article className="flex flex-col grow mt-10">
            <div className="relative flex flex-col items-center">
                <img
                    src={imgSrc}
                    alt={name}
                    className="z-10 shrink-0 rounded-full h-[158px] w-[158px]"
                />
                <div className="flex flex-col -mt-20 items-center px-20 pt-20 pb-3 rounded-lg bg-neutral-200 max-md:px-5">
                    <h3 className="text-4xl font-bold text-indigo-700">{name}</h3>
                    <p className="mt-8 text-xl text-center text-black pb-5">{description}</p>
                </div>
            </div>
        </article>
    );

    const cardsData = [
        {
            imageSrc: discover,
            title: "DISCOVER",
            description:
                "Discover tools to build financial confidence, manage money wisely, achieve goals, and plan effectively for a brighter financial future.",
            imageAlt: "Discover Image",
        },
        {
            imageSrc: learn,
            title: "LEARN",
            description:
                "Learn how to budget, save, invest, and plan effectively to achieve financial goals and secure your financial future for a better peace of mind.",
            imageAlt: "Learn Image",
        },
        {
            imageSrc: grow,
            title: "GROW",
            description:
                "Grow your financial confidence, build wealth, achieve goals, and create a secure future through effective planning and smart decisions.",
            imageAlt: "Grow Image",
        },
    ];

    const testimonialsData = [
        {
            imageSrc: john,
            name: "JOHN DOE",
            description:
                "Budget Buddies has been a tremendous tool in keeping up with my monthly expenses and budgeting for the future!",
        },
        {
            imageSrc: jane,
            name: "JANE DOE",
            description:
                "If it weren't for Budget Buddies, I never would have saved up for that family trip to Italy. Highly recommended!",
        },
        {
            imageSrc: janice,
            name: "JANICE DOE",
            description:
                "I didn't know the first thing about how to save money and budget propperly. And then I found Budget Buddies.",
        },
    ];

    return (
        <div className="flex flex-col bg-white">
            <section
                className="relative h-[68vh] bg-cover bg-center"
                style={{ backgroundImage: `url(${hero})` }}
            >
                <div className="absolute inset-0 bg-indigo-700 bg-opacity-40 flex flex-col justify-center items-start pl-20 max-md:items-center max-md:pl-0">
                    <h1 className="text-8xl font-bold text-white mb-8 max-md:text-center">
                        Financial Freedom <br /> is a click away
                    </h1>
                    <p className="text-4xl text-white mb-16 max-md:text-center">
                        Meet your Budget Buddy
                    </p>
                    <button
                        className="px-12 py-4 text-4xl font-bold text-white bg-indigo-700 rounded-lg"
                        onClick={handleLearnMoreClick}
                    >
                        LEARN MORE
                    </button>
                </div>
            </section>
            <div className="flex h-[20vh] px-8 m-4 max-md:flex-col max-md:px-4">
            <button
                    onClick={handleSimulationsClick}
                    className="flex justify-center items-center w-1/2 text-6xl font-bold whitespace-nowrap text-white bg-cover bg-center rounded-lg mx-4 max-md:w-full max-md:text-4xl max-md:mx-0 max-md:my-4"
                    style={{ backgroundImage: `url(${simulationsImage})` }}
                >
                    SIMULATIONS
                </button>
                <button
                    onClick={handleBudgetingClick}
                    className="flex justify-center items-center w-1/2 text-6xl font-bold whitespace-nowrap text-white bg-cover bg-center rounded-lg mx-4 max-md:w-full max-md:text-4xl max-md:mx-0 max-md:my-4"
                    style={{ backgroundImage: `url(${budgetingImage})` }}
                >
                    BUDGETING
                </button>
            </div>
            <div className="flex flex-col pb-9 bg-white">
                <header className="px-20 pt-7 pb-12 w-full bg-zinc-100 max-md:px-5 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col max-md:w-full">
                            <Card {...cardsData[0]} />
                        </div>
                        <div className="flex flex-col ml-5 max-md:ml-0 max-md:w-full">
                            <Card {...cardsData[1]} />
                        </div>
                        <div className="flex flex-col ml-5 max-md:ml-0 max-md:w-full">
                            <Card {...cardsData[2]} />
                        </div>
                    </div>
                </header>

                <main
                    className="self-center mt-9 text-6xl font-bold text-center text-indigo-700 max-md:max-w-full max-md:text-4xl">
                    TESTIMONIALS
                </main>

                <section className="mt-5 w-full max-md:max-w-full px-5">
                    <div className="flex gap-20 max-md:flex-col max-md:gap-0 px-20">
                        {testimonialsData.map((testimonial, index) => (
                            <Testimonial key={index} name={testimonial.name} description={testimonial.description} imgSrc={testimonial.imageSrc} />
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default HomeScreen;