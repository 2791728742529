import * as React from "react";
import { Form, Input, Button, Row, Col } from "antd";
import contactImage from "../public/contact.png";

const { TextArea } = Input;

const ContactScreen: React.FC = () => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        console.log("Form Values:", values);
    };

    return (
        <div className="flex flex-col bg-white">
        <Row>
            <Col xs={24} md={12}>
                <div className="p-8">
                    <h1 className="text-8xl font-bold mb-8 mt-5 text-center text-indigo-700">Let's talk.</h1>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: "Please enter your name" }]}
                        >
                            <Input className="w-full" />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                { required: true, message: "Please enter your email" },
                                { type: "email", message: "Please enter a valid email" },
                            ]}
                        >
                            <Input className="w-full" />
                        </Form.Item>
                        <Form.Item
                            name="subject"
                            label="Subject"
                            rules={[{ required: true, message: "Please enter a subject" }]}
                        >
                            <Input className="w-full" />
                        </Form.Item>
                        <Form.Item
                            name="message"
                            label="Message"
                            rules={[{ required: true, message: "Please enter a message" }]}
                        >
                            <TextArea rows={6} className="w-full" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="w-full bg-indigo-700 hover:bg-indigo-800">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
            <Col xs={24} md={12}>
                <div className="p-8">
                    <img
                        src={contactImage}
                        alt="Contact"
                        className="mt-5 w-full h-[calc(80vh)] object-cover rounded-lg"
                    />
                </div>
            </Col>
        </Row>
        </div>
    );
};

export default ContactScreen;