import * as React from "react";
import aboutUsImage from "../public/about-us-image.png";
import readyImage from "../public/ready-image.png";

const AboutUsScreen: React.FC = () => {
    return (
        <div className="flex flex-col bg-white">
            <div className="flex flex-col md:flex-row items-center">
                <img
                    src={aboutUsImage}
                    alt="About Us"
                    className="w-full md:w-1/5 md:h-auto object-cover p-5"
                />
                <div className="flex-1 p-6 text-center">
                    <p className="text-lg">
                        Budget Buddies was founded by a group of passionate young students who wanted to make financial education accessible, fun, and relatable for everyone. We understand that managing money can be overwhelming, especially when you're just starting out, so we created Budget Buddies to help simplify the process.
                        Our goal is to provide tools and resources that empower you to take control of your finances, no matter where you are in your journey. Through our interactive financial simulations, we allow you to experience real-life events in a game-like format, giving you the chance to make decisions, see the outcomes, and learn from your choices in a risk-free environment. This approach helps you understand how different financial decisions impact your life, so you can make smarter choices in the real world.
                        In addition to our simulations, we offer easy-to-use budgeting tools that help you track your income, expenses, and savings goals. Whether you're budgeting for the first time or looking to improve your financial habits, our tools are designed to keep you on track and motivated to reach your goals.
                        At Budget Buddies, we believe that learning about money should be engaging and stress-free. Join us today, and start building a solid foundation for your financial future in a way that's simple, fun, and completely in your control.
                    </p>
                </div>
            </div>
            <div className="relative">
                <img src={readyImage} alt="Ready" className="w-full h-[calc(60vh-85px)]" />
                <div className="absolute inset-0 flex items-center justify-center bg-indigo-700 bg-opacity-50">
                    <h2 className="[text-shadow:_0_8px_4px_rgb(0_0_0_/_0.6)] text-5xl font-bold text-white drop-shadow">So, are you ready to learn with us?</h2>
                </div>
            </div>
        </div>
    );
};


export default AboutUsScreen;